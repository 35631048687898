<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							Chick Sale
						</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> List </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Table Container Card -->
		<b-card no-body>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Date picker -->
					<b-col cols="12" md="7">
						<b-row>
							<b-col md="5">
								<label for="example-input">From Date</label>
								<b-form-datepicker
									id="from-date"
									placeholder="Choose a date"
									local="en"
									v-model="search_filter.from_date"
								/>
							</b-col>
							<b-col md="5">
								<label for="example-input">To Date</label>
								<b-form-datepicker
									id="to-date"
									placeholder="Choose a date"
									local="en"
									v-model="search_filter.to_date"
								/>
							</b-col>
							<b-col md="2 d-flex align-items-end">
								<b-button @click="getChickSale()" variant="success ">
									Filter
								</b-button>
							</b-col>
						</b-row>
					</b-col>
					<!-- Search -->
					<b-col
						cols="12"
						md="5"
						class="mt-2 d-flex justify-content-end align-items-center"
					>
						<div>
							<b-form-input
								type="text"
								class="form-control"
								placeholder="Search"
								@input="getChickSale()"
								v-model.trim="search_filter.input_field"
								style="border-radius: 4px"
							/>
						</div>
						<b-button
							@click="clearFilter"
							variant="secondary
 			 ml-1"
						>
							Clear
						</b-button>
						<b-button v-b-modal.chick-sale variant="primary mr-1 ml-1">
							Add
						</b-button>
						<b-button @click="excelDownload()" variant="warning">
							<feather-icon icon="DownloadIcon" size="16" />
						</b-button>
					</b-col>
				</b-row>
			</div>
			<div v-if="chickSale && chickSale.total > 0">
				<b-table
					:items="chickSale.data"
					responsive
					:fields="fields"
					show-empty
					empty-text="No matching records found"
					class="position-relative"
				>
					<template #cell(index)="data">
						<div class="ml-1">
							{{ chickSale.from + data.index }}
						</div>
					</template>
				</b-table>

				<div class="mx-2 mb-2">
					<b-pagination
						:value="chickSale.current_page"
						:total-rows="chickSale.total"
						:per-page="chickSale.per_page"
						align="right"
						@change="getChickSale"
					>
					</b-pagination>
				</div>
			</div>
			<div v-else class="d-flex flex-column align-items-center py-5">
				<b-img
					:src="
						require('@/assets/images/svg/infographics/chick_sale_infographic.svg')
					"
					class="mr-2"
				/>
				<h4 class="mt-2 Secondary">No chick sold yet</h4>
			</div>
			<!-- hatch mortality modal -->
			<b-modal
				id="chick-sale"
				title="Chick Sale"
				ok-only
				@ok="addChickSale"
				ok-title="Submit"
				ref="chick-sale"
			>
				<validation-observer ref="simpleRules">
					<form ref="form">
						<b-form-group>
							<template v-slot:label>
								Hatch ID<span class="text-danger">*</span>
							</template>
							<validation-provider
								#default="{ errors }"
								name="Hatch ID"
								rules="required"
							>
								<v-select
									v-model="selectedChickId"
									:clearable="false"
									label="hatch_id"
									:options="hatchList"
									:required="!selectedChickId"
									:reduce="(e) => e.hatch_id"
									@input="getAvailableChick"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group>
							<template v-slot:label>
								Price (Per chick)<span class="text-danger">*</span>
							</template>
							<validation-provider
								#default="{ errors }"
								name="Price (Per chick)"
								rules="required"
							>
								<b-form-input
									name="chick_rate"
									v-model="chick_rate"
									placeholder="Price (Per chick)"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group>
							<template v-slot:label>
								Available Chick<span class="text-danger">*</span>
							</template>
							<b-form-input
								name="total_chick"
								v-model="totalChick"
								value="totalChick"
								placeholder="Available Chick"
								:disabled="true"
							/>
						</b-form-group>
						<b-form-group>
							<template v-slot:label>
								Buyer<span class="text-danger">*</span>
							</template>
							<validation-provider
								#default="{ errors }"
								name="Buyer"
								rules="required"
							>
								<b-form-input
									name="buyer"
									v-model="buyer"
									placeholder="Buyer"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group>
							<template v-slot:label>
								Sold No Chick<span class="text-danger">*</span>
							</template>
							<validation-provider
								#default="{ errors }"
								name="Sold No Chick"
								rules="required"
							>
								<b-form-input
									name="sold_num_chick"
									v-model="sold_num_chick"
									placeholder="Sold No Chick"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<b-form-group>
							<template v-slot:label>
								Total cost<span class="text-danger">*</span>
							</template>
							<validation-provider
								#default="{ errors }"
								name="Total cost"
								rules="required"
							>
								<b-form-input
									name="total_amount"
									v-model="total_amount"
									placeholder="Total cost"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</form>
				</validation-observer>
			</b-modal>
		</b-card>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BImg,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BTabs,
	BTab,
	BCardText,
	BFormDatepicker,
	BFormSelect,
	BFormGroup,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useChickSaleList from "./useChickSaleList";
import saleStoreModules from "../saleStoreModules";
import axiosIns from "@/libs/axios";
import moment from "moment";
import { BASE_URL } from "@core/common/constants";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
	components: {
		BCard,
		BRow,
		BImg,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BTooltip,
		BCardText,
		BTabs,
		BTab,
		vSelect,
		BFormDatepicker,
		BFormSelect,
		BFormGroup,
		ValidationProvider,
		ValidationObserver,
	},
	data() {
		return {
			required,
			chickSale: null,
			buyer: null,
			total_chick: null,
			sold_num_chick: null,
			chick_rate: null,
			total_amount: null,
			totalChick: null,
			selectedChickId: null,
			search: null,
			rows: null,
			hatchList: null,
			fields: [
				{ key: "index", label: "s.no" },
				{ key: "hatch_id", label: "hatch-id" },
				{ key: `total_chick`, label: "total chick" },
				{ key: `sold_num_chick`, label: "sold num chick" },
				{ key: `chick_rate`, label: "chick rate" },
				{ key: `buyer`, label: "buyer" },
				{ key: `total_amount`, label: "amount" },
				{
					key: "created_at",
					label: "sell date",
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					},
				},
				// "Actions",
			],
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: "",
			},
		};
	},
	methods: {
		getChickSale(pageNo = 1) {
			const hatcheryId = this.$route.params.hatcheryId;
			const saleType = "chick";
			let url = `web/hatchery/${hatcheryId}/sale_type/${saleType}/getSaleInformation`;
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.chickSale = response.data.chick_saleInformationAll;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getPostHatchId() {
			const hatcheryId = this.$route.params.hatcheryId;
			axiosIns
				.get(`hatcheries/${hatcheryId}/post-hatch-list`)
				.then((response) => {
					this.hatchList = response.data.postHatchList;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		addChickSale(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					this.hatcheryId = this.$route.params.hatcheryId;
					const saleType = "chick";
					var data = new FormData();
					data.append("hatch_id", this.selectedChickId);
					data.append("hatchery_id", this.hatcheryId);
					data.append("sale_type", saleType);
					data.append("buyer", this.buyer);
					data.append("sold_num_chick", this.sold_num_chick);
					data.append("chick_rate", this.chick_rate);
					data.append("total_amount", this.total_amount);
					axiosIns
						.post(`web/storeChickSale`, data)
						.then((res) => {
							res;
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.$refs["chick-sale"].hide();
							this.getChickSale();
						})

						.catch((error) => {
							error;
						});
				}
			});
		},
		getAvailableChick() {
			const hatcheryId = this.$route.params.hatcheryId;
			const hatchId = this.selectedChickId;
			let url = `web/hatcheries/${hatcheryId}/post-hatch/${hatchId}/totalChick`;
			axiosIns
				.get(url)
				.then((response) => {
					this.totalChick = response.data.totalChick;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		excelDownload() {
			const saleType = "chick";
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(
					this.search_filter.from_date
				).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format(
					"YYYY-MM-DD"
				);
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-post-hatch-mortality/${saleType}`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.getSourceList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getSourceList();
				});
		},

		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getChickSale();
		},
	},
	created() {
		this.hatcheryId = this.$route.params.hatcheryId;
		this.getChickSale();
		this.getPostHatchId();
	},
	setup() {
		const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";

		// Register module
		if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
			store.registerModule(INVOICE_APP_STORE_MODULE_NAME, saleStoreModules);

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
				store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
		});

		const statusOptions = [
			"Downloaded",
			"Draft",
			"Paid",
			"Partial Payment",
			"Past Due",
		];

		const {
			fetchInvoices,
			saleTable,
			perPage,
			currentPage,
			totalInvoices,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refInvoiceListTable,
			statusFilter,
			refetchData,
			resolveInvoiceStatusVariantAndIcon,
			resolveClientAvatarVariant,
		} = useChickSaleList();

		return {
			fetchInvoices,
			saleTable,
			perPage,
			currentPage,
			totalInvoices,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refInvoiceListTable,

			statusFilter,

			refetchData,

			statusOptions,

			avatarText,
			resolveInvoiceStatusVariantAndIcon,
			resolveClientAvatarVariant,
		};
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
.header-title {
	height: 60px;
	border-bottom: 1px solid #ebe9f1;
	font-weight: 500;
	color: #636363;
	font-size: 1.714rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem;
}

.invoice-filter-select {
	min-width: 190px;

	::v-deep .vs__selected-options {
		flex-wrap: nowrap;
	}

	::v-deep .vs__selected {
		width: 100px;
	}
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
